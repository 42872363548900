var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PrimaryLayout',[_c('div',{staticClass:"section w-container flex flex-col my-16"},[_c('div',{staticClass:"font-sharp text-2xl"},[_vm._v(" Add an XCOPY Digital Asset ")]),_c('hr',{}),(_vm.activeAccount())?_c('div',[_c('AutoForm',{ref:"autoform",attrs:{"formConfig":{
          
          fields: [
              {modelname: 'title', label:'Title', type: 'text'}, 
              {modelname: 'contractAddress', label:'Contract Address' , type: 'text'},
              {modelname: 'primaryTokenId', label:'Primary Token ID' , type: 'text'},
              {modelname: 'metadataURI', label:'Metadata URI' , type: 'text'}, 
             
          ],
          submitRoute: 'createDigitalAsset'

          
          }},on:{"onPostSuccess":function($event){return _vm.routeTo(_vm.$router,{name:'digitalassetshow',params:{id:_vm.digitalAssetId}})},"onPostFailed":_vm.renderError,"onError":_vm.renderError}}),_c('ErrorBanner',{ref:"errorBanner"}),_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"flex-grow"}),_c('ButtonDefault',{staticClass:"bg-blue-500 hover:bg-blue-400 inline-block text-white rounded",on:{"clicked":function($event){return _vm.$refs.autoform.submit()}}},[_vm._v(" Submit ")])],1)],1):_vm._e(),(!_vm.activeAccount() )?_c('InfoPane',{staticClass:"mt-16"},[_vm._v(" Please sign in first. ")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }