<template>
  <div class="section py-4 text-white h-full bg-neutral-800" style="  min-height: 250px;">
    <div class=" h-full flex flex-col" style="min-height: 250px;">
      <div class=" flex flex-grow flex-row  ">
         
        <div class="w-1/3 p-2 ">
          <h3 class=" text-md   ">Documentation</h3>

        <a href="https://github.com/apexmfer/xcopycult-web" target="_blank"> Source Code </a>
         
           
          </div>
        <div class="w-1/3 h-full p-2">
          <h3 class=" text-md  ">Community &amp; Social</h3>

          <a href="https://xcopy.art/" target="_blank"> XCOPY Art </a>
        
         
         </div>


           <div class="w-1/3 text-gray-500 p-4">
           XCOPYCULT is not officially affiliated with XCOPY
         </div>


      </div>
       <div class=" flex flex-row w-full" style="height:60px;">


        <div class="text-center w-full"> Built by  <a href="https://twitter.com/ethereumdegen"> @EthereumDegen </a></div>
         
      </div>

    </div>
  </div>
</template>


<script>


export default {
  name: 'Footer',
  props: [],
  data() {
    return {

    }
  },
  created(){

  },
  methods: {

  }
}
</script>
