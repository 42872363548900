<template>
  
   <div  >

     Attribute List    {{modelname}}
     
    </div>
     
 
</template>


<script>

  
export default {
  name: 'AttributeListField',
  props: [  'modelname'  ],
  components: {
     
   },
  data() {
    return {
       
    }
  },
  mounted(){
      

  },
  methods: {
 
 
        
     
  }
}
</script>
